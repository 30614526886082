<template>
  <div>
    <el-container>
      <el-header>
        <el-row justify="space-between">
          <el-menu :unique-opened="true" :default-active="routepath" router :collapse="isCollapse" mode="horizontal" background-color='#141c23' text-color="#fff" active-text-color="#ffd04b">
            <!-- <el-menu-item v-for='(item,index) in sectionlist' @click="getMenu(item.name)" :key='index'>{{item.title}}</el-menu-item> -->
            <el-menu-item @click="goNew" :key='1'>发布信息</el-menu-item>
            <el-menu-item @click="goHuahui" :key='2'>发布花卉信息</el-menu-item>
            <el-menu-item @click="goWeihua" :key='3'>发布危化品信息</el-menu-item>
            <el-menu-item @click="$router.push('/datum/list')" :key='4'>信息列表</el-menu-item>
            <el-menu-item @click="$router.push('/datum/list/s2')" :key='5'>推广中信息</el-menu-item>
            <el-menu-item @click="$router.push('/datum/list/s7')" :key='6'>暂停推广信息</el-menu-item>
            <el-menu-item @click="$router.push('/picture/create')" :key='7'>图片生成</el-menu-item>
            <el-menu-item @click="$router.push('/picture/solarterm')" :key='8'>节气图片</el-menu-item>
            <el-menu-item style="color:gray;" :key="9" @click="showVersionDiv=true">当前版本：v{{version}}</el-menu-item>
            <!-- <el-menu-item style="color:gray;cursor: default;color:red;font-weight: bold;" v-if='compareVersion(newversion,version)'>最新版本：{{newversion}}</el-menu-item> -->
            <!-- <el-submenu v-for="(item,index) in menulist" :index="item.menuid+''" :key="index" router style="text-align:left">
              <template slot="title">
                <span>{{item.title}}</span>
              </template>
              <el-menu-item-group v-for="(it,ind) in item.children" :index="it.menuid+''" :key="ind">
                <el-menu-item :index="'/'+it.controller+'/'+it.action" style="padding-left:50px;">{{it.title}}</el-menu-item>
              </el-menu-item-group>
            </el-submenu> -->
            <el-menu-item @click="logout" style="float:right">退出</el-menu-item>
            <el-menu-item @click="chngPswdDiv = true" style="float:right">修改密码</el-menu-item>
            <el-menu-item style="float:right;color:red;cursor: default;">{{username}}-{{useraccount}}</el-menu-item>
          </el-menu>
        </el-row>
      </el-header>
      <el-container class="maincontent">
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
      <!-- <el-footer>
        <a href="http://beian.miit.gov.cn">鲁ICP备20002132号-1</a>
      </el-footer>-->
    </el-container>
    <el-dialog :visible.sync="chngPswdDiv" :close-on-click-modal="false">
      <el-form :model="pswdinfo" ref="pswdinfo" label-width="100px">
        <el-form-item label="原密码" :label-width="formLabelWidth" prop="oldpassword">
          <el-input v-model="pswdinfo.oldpassword" autocomplete="off" type="password"></el-input>
        </el-form-item>
        <el-form-item label="新密码" :label-width="formLabelWidth" prop="newpassword">
          <el-input v-model="pswdinfo.newpassword" autocomplete="off" type="password"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" :label-width="formLabelWidth" prop="cfrmnewpassword">
          <el-input v-model="pswdinfo.cfrmnewpassword" autocomplete="off" type="password"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetFormInfo('pswdinfo');chngPswdDiv = false">取 消</el-button>
        <el-button type="primary" @click="chngpswd">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="showVersionDiv" :close-on-click-modal="false" :title="'最新版本：v'+newversion" @opened="getVersionInfo">
      <div class="text-left padding-large-left" style="line-height:30px" v-html="versionHtml"></div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showVersionDiv = false">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      webname: sessionStorage.getItem("webname"),
      // menulist: JSON.parse(sessionStorage.getItem("ziaccountmenulist")),
      isCollapse: false,
      chngPswdDiv: false,
      pswdinfo: {
        oldpassword: "",
        newpassword: "",
        cfrmnewpassword: "",
      },
      formLabelWidth: "150px",
      username: sessionStorage.getItem("ziaccountname"),
      useraccount: sessionStorage.getItem("ziaccountaccount"),
      version: window.version,
      mustUpdate: sessionStorage.getItem('mustUpdate'),
      newversion: sessionStorage.getItem("version"),
      // showVersion:false,
      showVersionDiv: false,
      versioninfo: "更新内容：<br />" + sessionStorage.getItem("versioninfo").replace(/(\r\n)|\r|\n/g, '<br />'),

    };
  },
  methods: {
    ...mapActions({
      changePswd: "auth/changePassword",
    }),
    chngpswd() {
      if (this.pswdinfo.newpassword != this.pswdinfo.cfrmnewpassword) {
        this.$message({
          showClose: true,
          type: "error",
          message: "两次输入的新密码不一致",
        });
      } else {
        this.$axios({
          method: "post",
          url: this.HOST + "/index/chngPswd",
          data: {
            info: this.pswdinfo,
          },
        }).then((res) => {
          if (res.data.status == 1) {
            this.$message({
              showClose: true,
              type: "info",
              message: "修改成功",
            });
            this.resetFormInfo("pswdinfo");
            this.chngPswdDiv = false;
          }
        });
      }
    },
    getVersionInfo() {
      if (this.newversion == 'undefined') {
        this.$axios({
          url: this.HOST + '/index/getinfo',
          method: 'get',
          data: {}
        }).then(res => {
          if (res.data.status == 1) {
            this.newversion = res.data.version;
            this.versioninfo = "更新内容：<br />" + res.data.versionInfo.replace(/(\r\n)|\r|\n/g, '<br />');
            sessionStorage.setItem('version', response.data.version);
            sessionStorage.setItem('versioninfo', response.data.versionInfo);
            sessionStorage.setItem('mustUpdate', response.data.mustUpdate);
          }
        });
      }
    },
    compareVersion(newversion, oldversion) {
      oldversion = oldversion.replace(/[^0-9.]/g, '').split('.');
      newversion = newversion.replace(/[^0-9.]/g, '').split('.');
      let i = 0;
      while (typeof (oldversion[i]) != 'undefined' && typeof (newversion[i]) != 'undefined') {
        if (parseInt(oldversion[i]) < parseInt(newversion[i])) return true;// 旧版本号小于新版本号
        i++;
      }
      if (oldversion.length < newversion.length) return true;
      return false;
    },
    logout() {
      sessionStorage.removeItem("ziaccountuuid");
      sessionStorage.removeItem("ziaccountname");
      sessionStorage.removeItem("ziaccountaccount");
      sessionStorage.removeItem("ziaccounttoken");
      sessionStorage.removeItem("ziaccountislogin");
      this.$router.push("/login");
    },
    showmenu() {
      this.isCollapse = !this.isCollapse;
    },
    //重置菜单编辑对话框内容
    resetFormInfo(formName) {
      this.$refs[formName].resetFields();
    },
    goNew(){
      if(sessionStorage.getItem('third_party')==1){
        this.$router.push('/datum/isvnew')
      }else{
        this.$router.push('/datum/new')
      }
    },
    goHuahui(){
      if(sessionStorage.getItem('third_party')==1){
        this.$router.push('/datum/isvhuahui')
      }else{
        this.$router.push('/datum/huahui')
      }
    },
    goWeihua(){
      if(sessionStorage.getItem('third_party')==1){
        this.$router.push('/datum/isvweihua')
      }else{
        this.$router.push('/datum/weihua')
      }
    }
  },
  computed: {
    ...mapState({
      navList: (state) => state.navBar.navList,
    }),
    routepath() {
      return this.$route.path;
    },
    menu_act() {
      return this.isCollapse ? "菜单" : "收起菜单";
    },
    aside_width() {
      return this.isCollapse ? "50px" : "220px";
    },
    versionHtml() {
      if (this.compareVersion(this.newversion, this.version) && sessionStorage.getItem('mustUpdate') == 1) {
        return this.versioninfo + "<br />本版本为强制更新，请按 ctrl+F5 进行更新";
      }
      return this.versioninfo;
    },
    // versioninfo() {
    //   return ("更新内容：\r\n" + sessionStorage.getItem("versioninfo")).replace(/(\r\n)|\r|\n/g, '<br />');
    // }
  },
  created() {
    if (this.compareVersion(this.newversion, this.version)) {
      if (sessionStorage.getItem('mustUpdate') == 1) {
        this.$alert(this.versionHtml, "版本更新：v" + this.version + " → v" + this.newversion, {
          confirmButtonText: '确定',
          // type: 'warning',
          dangerouslyUseHTMLString: true
        })
      } else {
        this.$notify({
          title:
            "版本更新：v" +
            this.version +
            " → v" + this.newversion,
          message: this.versioninfo + "<br />请按 ctrl+F5 刷新页面",
          duration: 2000,
          dangerouslyUseHTMLString: true,
          position: "bottom-right",
          type: 'warning',
          showClose: true
        });
      }

    }
  },
};
</script>

<style>
.flex {
  display: flex;
  justify-content: space-between;
  cursor: initial;
}
.el-menu-item.is-active {
  border-right: none;
}
.el-notification {
  white-space: pre-wrap !important;
}
</style>
